import React, { useState } from 'react';

function ModalDialog(props) {
  const [activeItem, setActiveItem] = useState('');

  function toggleModal() {
    setActiveItem(activeItem === '' ? 'open' : '');
  }

  return (
    <div className="bio">
      <button className="speaker-button open-button" onClick={toggleModal}>
        {props.buttonImage}
        <h4>{props.buttonText}</h4>
      </button>
      <div className={`dialogbkgd ${activeItem}`}>
        <dialog className={`modal ${activeItem}`} id={props.modalId}>
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
          <button className="close-button" onClick={toggleModal}>
            X
          </button>
        </dialog>
      </div>
    </div>
  );
}

export default ModalDialog;
